import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { TFunction } from "i18next";
import { DateTime } from "luxon";
import { ComponentType, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    VariableSizeList as _VariableSizeList,
    VariableSizeListProps,
    ListChildComponentProps,
} from "react-window";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactWindowScroller } from "react-window-scroller";

import Accordion from "components/Accordion/Accordion";
import List from "components/List/List";
import Loading from "components/Loading/Loading";
import {
    GetAllAssetDetailsQuery,
    GetAllAssetDetailsQueryVariables,
} from "graphql-types/graphql";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import { getBuildingType } from "./AssetDetails.helpers";
import { AssetDetailsContent } from "./AssetDetailsContent";
import { ASSET_DETAILS_QUERY } from "./assetDetailsQuery";

const VariableSizeList =
    _VariableSizeList as ComponentType<VariableSizeListProps>;

const buildAccordionList = (
    building: GetAllAssetDetailsQuery["location"]["buildings"][number],
    t: TFunction
) => [
    {
        label: t(
            "assetDetailsComponent.nationalBuildingId",
            "National Building ID"
        ),
        description: building.nationalIdentifier || DASH_SEPARATOR,
    },
    {
        label: t("assetDetailsComponent.address", "Address"),
        description: building.longAddress || DASH_SEPARATOR,
    },
    {
        label: t("assetDetailsComponent.area", "Area"),
        description: building.buildingArea
            ? `${building.buildingArea} ${t("common.units.area.m2", "m²")}`
            : DASH_SEPARATOR,
    },
    {
        label: t("assetDetailsComponent.buildingType", "Building Type"),
        description: getBuildingType(building),
    },
    {
        label: t(
            "assetDetailsComponent.constructionYear",
            "Year of Construction"
        ),
        description:
            DateTime.fromISO(building.constructionDate).year || DASH_SEPARATOR,
    },
];

const BuildingsList = ({ locationId }: { locationId: string }) => {
    const { t } = useTranslation();

    const { data, loading } = useQuery<
        GetAllAssetDetailsQuery,
        GetAllAssetDetailsQueryVariables
    >(ASSET_DETAILS_QUERY, {
        variables: { locationId },
    });

    const [location, editsCount, buildings] = useMemo(
        () => [
            data?.location,
            data?.changeLogsFieldsCount ?? 0,
            data?.location.buildings ?? [],
        ],
        [data]
    );

    const isBuildingExpanded = useMemo(
        () => Array(buildings.length).fill(true),
        [buildings]
    );

    const BuildingRenderer = useCallback(
        ({
            building,
            index,
            style,
            parentRef,
        }: {
            building: GetAllAssetDetailsQuery["location"]["buildings"][number];
            index: number;
            style: any;
            parentRef: any;
        }) => {
            const listContent = buildAccordionList(building, t);

            return (
                <Box style={style}>
                    <Accordion
                        paneTitle={
                            building.nationalIdentifier ||
                            building.shortAddress ||
                            DASH_SEPARATOR
                        }
                        defaultExpanded={true}
                        onChange={() => {
                            isBuildingExpanded[index] =
                                !isBuildingExpanded[index];

                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            if (parentRef && parentRef.current)
                                parentRef.current.resetAfterIndex(0);
                        }}
                    >
                        <List listContent={listContent} />
                    </Accordion>
                </Box>
            );
        },
        [isBuildingExpanded, t]
    );

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    if (buildings.length < 20) {
        return (
            <AssetDetailsContent asset={location} editsCount={editsCount}>
                {buildings.map((building, index) => (
                    <BuildingRenderer
                        key={index}
                        building={building}
                        index={index}
                        style={{ width: "100%", marginBottom: "1rem" }}
                        parentRef={null}
                    />
                ))}
            </AssetDetailsContent>
        );
    }

    return (
        <ReactWindowScroller>
            {(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                { ref, outerRef, onScroll, style }
            ) => {
                const Row = ({ index, style }: ListChildComponentProps) => (
                    <BuildingRenderer
                        key={index}
                        building={buildings[index]}
                        index={index}
                        style={style}
                        parentRef={ref}
                    />
                );

                const getRowHeight = (index: number) =>
                    isBuildingExpanded[index] ? 280 : 58;

                return (
                    <AssetDetailsContent
                        asset={location}
                        editsCount={editsCount}
                    >
                        <VariableSizeList
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            ref={ref}
                            style={style}
                            outerRef={outerRef}
                            onScroll={onScroll}
                            itemSize={getRowHeight}
                            height={window.innerHeight}
                            itemCount={buildings.length}
                        >
                            {Row}
                        </VariableSizeList>
                    </AssetDetailsContent>
                );
            }}
        </ReactWindowScroller>
    );
};

export default BuildingsList;
