import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { BenchmarkPanel } from "components/Benchmark";
import { BenchmarkMarkers } from "components/types";
import { useAssessmentDataTypeContext } from "containers/AssetPage/AssessmentDataTypeContext";
import { AssetContext } from "containers/AssetPage/AssetPage";
import {
    BenchmarkType,
    GetLegacyBenchmarksByAssetIdQuery,
    GetLegacyBenchmarksByAssetIdQueryVariables,
    LegacyBenchmarkValueType,
} from "graphql-types/graphql";
import { useBenchmarkMarker, useLegacyBenchmarkMarker } from "hooks";
import { LEGACY_BENCHMARKS_BY_ASSET_ID_QUERY } from "queries/assetLegacyBenchmarksQuery";
import { BenchmarksContext } from "utils/benchmarksContext";

import { MUIAssessmentChart } from "./MUIAssessmentChart";
import { AssessmentStats } from "../types";

type Props = {
    isYearly: boolean;
    assessmentStats: AssessmentStats[];
};

function AssessmentChart(props: Props) {
    const { assessmentStats, isYearly } = props;
    const { t } = useTranslation();
    const { locationId } = useParams<{
        locationId: string;
    }>();

    const { useLegacyBenchmark } = useFlags();

    const asset = useContext(AssetContext);
    const benchmarkContext = useContext(BenchmarksContext);

    const { assessmentType } = useAssessmentDataTypeContext();

    const { convertToBenchmarkMarker } = useBenchmarkMarker();
    const { convertToLegacyBenchmarkMarker } = useLegacyBenchmarkMarker();

    const [markers, setMarkers] = useState<BenchmarkMarkers>([]);

    const { data: assetLegacyBenchmarkData } = useQuery<
        GetLegacyBenchmarksByAssetIdQuery,
        GetLegacyBenchmarksByAssetIdQueryVariables
    >(LEGACY_BENCHMARKS_BY_ASSET_ID_QUERY, {
        skip: !useLegacyBenchmark,
        variables: {
            valueType: LegacyBenchmarkValueType.EMISSION,
            assetId: locationId,
        },
    });

    const showBenchmarks = !isYearly && asset.ownedArea;

    useEffect(() => {
        const { benchmarks } = benchmarkContext;

        const area = asset.ownedArea;

        const assetBenchmarks = benchmarks.filter(
            (benchmark) =>
                benchmark.type === BenchmarkType.BASELINE ||
                (benchmark.type === BenchmarkType.MARKET &&
                    benchmark.countryCode === asset.countryCode)
        );

        if (!assetBenchmarks.length || !area) {
            return;
        }

        const pendingBenchmarkMarkers = convertToBenchmarkMarker(
            assessmentType,
            assetBenchmarks
        );

        const benchmarkMarkers = pendingBenchmarkMarkers.map((marker) => {
            return {
                ...marker,
                value: (marker.value ?? 0) * area,
                isVisible: true,
            };
        });

        const pendingLegacyMarkers = convertToLegacyBenchmarkMarker(
            assetLegacyBenchmarkData?.getLegacyBenchmarksByAssetId ?? []
        );

        const legacyBenchmarks = pendingLegacyMarkers.map((marker) => {
            const values = marker.values.map((value) => {
                return {
                    ...value,
                    value: (value.value ?? 0) * area,
                };
            });

            return {
                ...marker,
                values,
                isVisible: true,
            };
        });

        setMarkers([...benchmarkMarkers, ...legacyBenchmarks]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetLegacyBenchmarkData]);

    function onToggleMarkerVisibility(markerId: string, isVisible: boolean) {
        const updatedBenchmarkMarkers: BenchmarkMarkers = markers.map(
            (marker) => {
                if (marker.id === markerId) {
                    return {
                        ...marker,
                        isVisible,
                    };
                }

                return marker;
            }
        );

        setMarkers(updatedBenchmarkMarkers);
    }

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                    {t("assessmentOverview.emissions", "Emissions")}
                </Typography>
                {showBenchmarks && (
                    <Box marginLeft="auto">
                        <BenchmarkPanel
                            title={asset.displayName}
                            benchmarkMarkers={markers}
                            onToggleMarkerVisibility={onToggleMarkerVisibility}
                        />
                    </Box>
                )}
            </Box>

            <MUIAssessmentChart
                isYearly={isYearly}
                benchmarkMarkers={markers}
                assessmentsData={assessmentStats}
            />
        </>
    );
}

export default AssessmentChart;
