import { GridColDef } from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { capitalize } from "lodash";
import { match } from "ts-pattern";

import { AutomaticDataSourceStatusCell } from "components/Cells/AutomaticDataSourceStatusCell";
import { DataSourceStatus } from "components/Cells/DataStatusCell";
import NationalBuildingIdsCell from "components/Cells/NationalBuildingIdsCell";
import { dateSorter } from "containers/DataOverviewPage/dataOverview.helper";
import { ConsumptionType, IntegrationType } from "graphql-types/graphql";
import { validateAndFormatDateString } from "utils/date.utils";
import { DASH_SEPARATOR } from "utils/strings.helpers";
import { getConsumptionTypeTranslation } from "utils/translations";

import { AutomaticPropertyAllocationObject } from "./automaticSourcesTable.types";
import {
    AutomaticSource,
    getAutomaticSourceStatus,
    getAutomaticSourceToDate,
} from "../AssetSources.helpers";
export const getColumns = (t: TFunction): GridColDef[] => {
    return [
        {
            field: "source",
            headerName: t("common.labels.source", "Source", {
                ns: "translation",
            }),
            flex: 1,
            renderCell: ({ value }) =>
                AutomaticDataSourceStatusCell({
                    source: value.source,
                    status: value.status,
                }),
            sortComparator: (v1, v2) => v1.source.localeCompare(v2.source),
            sortable: true,
        },
        {
            field: "address",
            headerName: t("columns.address", "Address", { ns: "report" }),
            flex: 1,
            sortable: true,
        },
        {
            field: "nationalPropertyId",
            headerName: t(
                "columns.nationalPropertyId",
                "National Property ID",
                { ns: "report" }
            ),
            renderCell: ({ value }) =>
                NationalBuildingIdsCell(value.split(",")),
            flex: 0.75,
            sortable: true,
        },
        {
            field: "identifier",
            headerName: t("columns.identifierLong", "Identifier", {
                ns: "report",
            }),
            flex: 0.75,
            sortable: true,
        },
        {
            field: "consumptionType",
            headerName: t("columns.consumptionType", "Consumption Type", {
                ns: "report",
            }),
            valueFormatter: (value?: ConsumptionType) =>
                value ? getConsumptionTypeTranslation(value) : DASH_SEPARATOR,
            flex: 0.75,
            sortable: true,
        },
        {
            field: "from",
            headerName: t("common.timeframe.from", "From", {
                ns: "translation",
            }),
            valueFormatter: (value: string) =>
                validateAndFormatDateString(value),
            flex: 0.5,
            sortComparator: dateSorter,
            sortable: true,
        },
        {
            field: "to",
            headerName: t(
                "common.timeframe.to_lastReading",
                "To / Last Reading",
                { ns: "translation" }
            ),
            renderCell: ({ row }) => getAutomaticSourceToDate(row, t),
            flex: 0.5,
            sortComparator: dateSorter,
            sortable: true,
        },
    ];
};

export const getAllocationColumns = (t: TFunction): GridColDef[] => [
    {
        field: "propertyAllocation",
        headerName: t("common.allocation", "Property Allocation", {
            ns: "translation",
        }),
        valueFormatter: (value: AutomaticPropertyAllocationObject) => {
            const { allocationObjectType, propertyIds } = value;
            return `${capitalize(allocationObjectType)} ${
                propertyIds.length > 0 ? `(${propertyIds.length})` : ""
            }`;
        },
        flex: 0.5,
        sortable: true,
    },
];

export const getStatusColor = (
    status: DataSourceStatus
): "active" | "expired" | "manual" => {
    return match(status)
        .with(DataSourceStatus.ACTIVE, () => "active" as const)
        .with(DataSourceStatus.EXPIRED, () => "expired" as const)
        .with(DataSourceStatus.MANUAL, () => "manual" as const)
        .exhaustive();
};

export const mapAutomaticSourceToIntegrationType = (type: string) => {
    if (type === "EPC_PROXY") {
        return IntegrationType.EPC;
    }

    return type as IntegrationType;
};

export const formatAutomaticDataRows = (sources: AutomaticSource[]) => {
    return sources.map((source) => ({
        ...source,
        type: mapAutomaticSourceToIntegrationType(source.type),
        source: {
            status: getAutomaticSourceStatus(source.status),
            source: source.source,
        },
        propertyAllocation: {
            allocationObjectType: source.allocationObjectType,
            propertyIds: source.propertyIds,
        },
    }));
};
