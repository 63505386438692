import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { DeleteModal } from "components/Modals/DeleteModal";
import InfoPopover from "components/Popovers/InfoPopover";
import { Table, TablePagination } from "components/Table";
import { getAssessmentsOverviewCacheKey } from "containers/AssetPage/AssetOverview/assetOverviewQuery";
import {
    DeleteAssessmentMutation,
    DeleteAssessmentMutationVariables,
    GetAssetManualSourcesQuery,
    GetAssetManualSourcesQueryVariables,
} from "graphql-types/graphql";
import { DELETE_ASSESSMENT } from "queries/assessmentQueries";

import { formatRows, getColumns } from "./manualSourcesTable.helpers";
import { ASSET_MANUAL_SOURCES } from "./manualSourcesTable.query";
import { ManualAssessment } from "./manualSourcesTable.types";
import { ManualConsumptionModal } from "../ManualConsumptionModal";

export const ASSET_PAGE_HEADER_HEIGHT = 400;
const ASSET_GRID_TABLE_HEIGHT = `calc(100vh - ${ASSET_PAGE_HEADER_HEIGHT}px)`;

type Props = {
    locationId: string;
};

export const ManualSourcesTable = (props: Props) => {
    const { locationId } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const [showAddEntryModal, setShowAddEntryModal] = useState(false);
    const [deleteId, setDeleteId] = useState<string | null>(null);
    const [editEntry, setEditEntry] = useState<ManualAssessment | null>(null);

    const {
        data: assessmentOriginData,
        loading,
        previousData: assessmentOriginDataPrevious,
    } = useQuery<
        GetAssetManualSourcesQuery,
        GetAssetManualSourcesQueryVariables
    >(ASSET_MANUAL_SOURCES, {
        variables: { locationId },
    });

    const [deleteAssessment, { loading: deleteLoading }] = useMutation<
        DeleteAssessmentMutation,
        DeleteAssessmentMutationVariables
    >(DELETE_ASSESSMENT, {
        refetchQueries: [
            { query: ASSET_MANUAL_SOURCES, variables: { locationId } },
        ],
        update(cache) {
            cache.evict({
                id: getAssessmentsOverviewCacheKey(locationId),
            });
        },
    });

    const toggleAddEntryModal = useCallback(() => {
        setShowAddEntryModal(!showAddEntryModal);
        if (editEntry) {
            const timeBeforeModalIsHidden = 500;
            setTimeout(() => setEditEntry(null), timeBeforeModalIsHidden);
        }
    }, [showAddEntryModal, setEditEntry, editEntry]);

    const handleDelete = useCallback(
        (isReportToBeDeleted: boolean) => {
            if (!deleteId) return;

            if (isReportToBeDeleted) {
                toast.promise(
                    deleteAssessment({
                        variables: { input: { id: deleteId } },
                    }),
                    {
                        success: {
                            render() {
                                return t(
                                    "manualSources.toast.success",
                                    "Entry deleted successfully"
                                );
                            },
                            type: "default",
                            hideProgressBar: true,
                        },
                        error: t(
                            "manualSources.toast.error",
                            "Error deleting entry"
                        ),
                    }
                );
            }

            setDeleteId(null);
        },
        [setDeleteId, deleteId, deleteAssessment, t]
    );

    const assessments = useMemo(() => {
        const data = assessmentOriginData ?? assessmentOriginDataPrevious;

        return data?.location.manualAssessments.flat() ?? [];
    }, [assessmentOriginData, assessmentOriginDataPrevious]);

    const handleEdit = useCallback(
        (entry: ManualAssessment) => {
            setEditEntry(entry);
            toggleAddEntryModal();
        },
        [toggleAddEntryModal]
    );

    const columns = useMemo(
        () => getColumns(t, handleEdit, setDeleteId),
        [t, handleEdit]
    );

    const rows = useMemo(() => formatRows(assessments, t), [assessments, t]);
    const apiRef = useGridApiRef();
    const fileName = `${assessmentOriginData?.location.displayName} - ${t(
        "manualSources.fileName",
        "manual_sources"
    )}`;

    return (
        <>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"100%"}
                alignItems={"flex-end"}
                mb={4}
            >
                <Box sx={{ maxWidth: "750px" }}>
                    <Box display={"flex"} gap={2} fontWeight={"bold"}>
                        {t(
                            "manualSources.header.title",
                            "Easily add your meter data manually."
                        )}
                        <InfoPopover
                            element={t(
                                "manualSources.header.description",
                                "All manual meter entries without documentation will be categorized as low quality data, it is your responsibility to provide documentation and sourcing during an audit.",
                                { ns: "translation" }
                            )}
                        />
                    </Box>
                    <Typography
                        display={"inline-block"}
                        variant="caption"
                        color={theme.palette.grey[600]}
                        mt={2}
                    >
                        {t(
                            "manualSources.header.subtext",
                            "Entries will be reflected in your platform the next business day.",
                            { ns: "translation" }
                        )}
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box sx={{ mr: 3 }}>
                        {rows.length} {t("pageheader.data", "Data Sources")}
                    </Box>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={toggleAddEntryModal}
                    >
                        {t("manualSources.header.addEntry", "Add Entry")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            apiRef.current.exportDataAsCsv({
                                fileName,
                            })
                        }
                    >
                        {t("common.download", "Download", {
                            ns: "translation",
                        })}
                    </Button>
                </Box>
            </Box>

            <ManualConsumptionModal
                assessments={assessments}
                isOpen={showAddEntryModal}
                onClose={toggleAddEntryModal}
                locationId={locationId}
                editEntry={editEntry}
            />

            <Paper sx={{ p: 1 }}>
                <Table
                    columns={columns}
                    rows={rows}
                    minHeight="400px"
                    setCustomHeight={ASSET_GRID_TABLE_HEIGHT}
                    slotProps={{
                        pagination: {
                            ActionsComponent: TablePagination,
                        },
                    }}
                    apiRef={apiRef}
                    isLoading={loading || deleteLoading}
                />
            </Paper>

            <DeleteModal isOpen={!!deleteId} onClose={handleDelete} />
        </>
    );
};
