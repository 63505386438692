import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ChangeLogTable } from "components/ChangeLog/ChangeLogTable";
import { useChangeLogQuery } from "components/ChangeLog/useChangeLogQuery";
import { BaseModal } from "components/Modal/BaseModal";
import { GetAllAssetDetailsQuery } from "graphql-types/graphql";

import AssetMasterData from "./AssetMasterData";
import { AssetPropertyList } from "./components";

type AssetDetailsContentType = {
    asset: GetAllAssetDetailsQuery["location"] | undefined;
    editsCount: number;
    children: React.ReactNode;
};

type AssetChangeLogModalType = {
    isOpen: boolean;
    assetId: string;
    assetName: string;
    editsCount: number;
    handleClose: () => void;
};

const ChangeLogBlock = ({
    onClick,
    editsCount,
}: {
    onClick: () => void;
    editsCount: number;
}) => {
    const { t } = useTranslation();

    return (
        <Stack
            sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: 14,
                padding: 3,
                gap: 2,
            }}
        >
            <Typography fontWeight={700} fontSize="inherit">
                {t("navbar.changeLog", "Change Log")}
            </Typography>

            {editsCount ? (
                <Link component="button" onClick={onClick}>
                    {t("settings.edit", { count: editsCount })}
                </Link>
            ) : (
                <Typography
                    color="grey.500"
                    fontStyle="italic"
                    fontSize="inherit"
                >
                    {t(
                        "asset.changeLog.noChanges",
                        "No changes have been made"
                    )}
                </Typography>
            )}
        </Stack>
    );
};

export const ChangeLogModal = ({
    isOpen,
    assetId,
    assetName,
    editsCount,
    handleClose,
}: AssetChangeLogModalType) => {
    const { t } = useTranslation();

    const { data, loading } = useChangeLogQuery(assetId, !isOpen);

    return (
        <BaseModal
            fullScreen
            sx={{ m: 12 }}
            open={isOpen}
            title={`${assetName} - ${t("navbar.changeLog", "Change Log")}`}
            handleClose={handleClose}
        >
            <Trans
                i18nKey="asset.changeLog.editDescription"
                values={{ edit: editsCount }}
            />

            <ChangeLogTable
                changeLogs={data}
                isLoading={loading}
                assetName={assetName}
            />
        </BaseModal>
    );
};

export const AssetDetailsContent = ({
    asset,
    editsCount,
    children,
}: AssetDetailsContentType) => {
    const { t } = useTranslation();
    const { useConsumptionAllocation } = useFlags();

    const [openChangeLog, setOpenChangeLog] = useState(false);

    if (!asset) {
        return (
            <Typography variant="h4" color="grey.400">
                {t(
                    "portfolioDashboard.sidePanel.buildingDetails.notFound",
                    "No building found with this National Building ID"
                )}
            </Typography>
        );
    }

    return (
        <Box
            sx={{
                display: "grid",
                gap: 4,
                gridTemplateColumns: "3fr 7fr",
            }}
        >
            <Box>
                <AssetMasterData asset={asset} />
                <ChangeLogBlock
                    editsCount={editsCount}
                    onClick={() => setOpenChangeLog(true)}
                />
            </Box>

            {!useConsumptionAllocation && (
                <Box>
                    <Typography variant="h3" mb={4}>
                        {t("assetDetailsComponent.buildings", "Buildings")}
                    </Typography>
                    {children}
                </Box>
            )}

            {useConsumptionAllocation && (
                <Box>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        mb={4}
                    >
                        <Typography variant="h3" fontWeight={700}>
                            {t(
                                "assetDetailsComponent.propertyList.title",
                                "Properties"
                            )}
                        </Typography>
                        <Box justifyContent="flex-end">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => console.log("Edit")}
                            >
                                {t(
                                    "assetDetailsComponent.propertyList.configure",
                                    "Configure Properties"
                                )}
                            </Button>
                        </Box>
                    </Stack>
                    <AssetPropertyList assetId={asset.id} />
                </Box>
            )}

            <ChangeLogModal
                isOpen={openChangeLog}
                assetId={asset.id}
                assetName={asset.displayName || "-"}
                editsCount={editsCount}
                handleClose={() => setOpenChangeLog(false)}
            />
        </Box>
    );
};
