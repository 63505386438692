import { S } from "./dataSourceDrawer.styles";
import { AllocationNoteField, OwnershipField } from "./DrawerFields";
import { AutomaticSourceRowData } from "../automaticSourcesTable.types";

type Props = {
    data: AutomaticSourceRowData;
};

export const AllocationDetailsPanel = (props: Props) => {
    const { data } = props;

    return (
        <S.Panel>
            <OwnershipField
                id={data.integrationId}
                landlordOwnership={data.landlordOwnership ?? 0}
                tenantOwnership={data.tenantOwnership ?? 0}
                otherOwnership={data.otherOwnership ?? 0}
            />

            <AllocationNoteField
                id={data.integrationId}
                note={data.allocationNote ?? ""}
            />
        </S.Panel>
    );
};
