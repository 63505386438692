import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
    UpdateAssetIntegrationMutation,
    UpdateAssetIntegrationMutationVariables,
} from "graphql-types/graphql";

import { DrawerField } from "./DrawerField";
import { ownershipsEquals100 } from "./drawerFields.helpers";
import { UPDATE_ASSET_INTEGRATION } from "./drawerFields.query";
import { EditableFieldList } from "./EditableFieldList";

type Props = {
    id: string;
    landlordOwnership: number;
    tenantOwnership: number;
    otherOwnership: number;
};

export const OwnershipField = (props: Props) => {
    const { id, landlordOwnership, tenantOwnership, otherOwnership } = props;

    const { t } = useTranslation();

    const [updateAssetIntegration] = useMutation<
        UpdateAssetIntegrationMutation,
        UpdateAssetIntegrationMutationVariables
    >(UPDATE_ASSET_INTEGRATION);

    const [ownership, setOwnership] = useState([
        {
            label: t("asset.sourceDrawer.landlord", "Landlord"),
            value: landlordOwnership,
            isChecked: landlordOwnership > 0,
            savedValue: landlordOwnership,
        },
        {
            label: t("asset.sourceDrawer.tenant", "Tenant"),
            value: tenantOwnership,
            isChecked: tenantOwnership > 0,
            savedValue: tenantOwnership,
        },
        {
            label: t("assetDetailsComponent.other", "Other"),
            value: otherOwnership,
            isChecked: otherOwnership > 0,
            savedValue: otherOwnership,
        },
    ]);

    const handleCancel = () => {
        setOwnership((prev) =>
            prev.map((item) => ({
                ...item,
                value: item.savedValue,
            }))
        );
    };

    const handleSave = () => {
        const variables = {
            id,
            update: {
                landlordOwnership: ownership[0].value,
                tenantOwnership: ownership[1].value,
                otherOwnership: ownership[2].value,
            },
        };

        setOwnership(
            ownership.map((item) => ({
                ...item,
                isChecked: item.value > 0,
                savedValue: item.value,
            }))
        );
        updateAssetIntegration({ variables });
    };

    const handleEditItem = (index: number, value: number) => {
        setOwnership((prev) =>
            prev.map((item, i) => (i === index ? { ...item, value } : item))
        );
    };

    const handleCheckItem = (index: number) => {
        setOwnership((prev) =>
            prev.map((item, i) =>
                i === index ? { ...item, isChecked: !item.isChecked } : item
            )
        );
    };

    return (
        <DrawerField
            label={t("asset.sourceDrawer.ownershipType", "Ownership Type")}
            tooltip="ownershipType"
            onSave={handleSave}
            onCancel={handleCancel}
            isSaveEnabled={ownershipsEquals100(ownership)}
        >
            <EditableFieldList
                items={ownership}
                onEditItem={handleEditItem}
                onCheckItem={handleCheckItem}
            />
        </DrawerField>
    );
};
