import { PropsWithChildren, useState } from "react";

import { TabsBox, TabsContainer, tabIndicatorProps } from "./navTab.styles";

function samePageLinkNavigation(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

export const NavTabs = (props: PropsWithChildren) => {
    const [value, setValue] = useState(1);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (
            event.type !== "click" ||
            (event.type === "click" &&
                samePageLinkNavigation(
                    event as React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ))
        ) {
            setValue(newValue);
        }
    };

    return (
        <TabsContainer>
            <TabsBox
                TabIndicatorProps={tabIndicatorProps}
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                role="navigation"
            >
                {props.children}
            </TabsBox>
        </TabsContainer>
    );
};
