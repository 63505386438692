import { Box, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
    DataGrid,
    GridColDef,
    GridCellParams,
    GridRowsProp,
    DataGridProps,
    GridValidRowModel,
    GridCellModesModel,
    GridCellModes,
    GridEventListener,
} from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { getValueFormatter } from "../../utils/report.helpers";

type Props = {
    rows: GridRowsProp;
    columns: GridColDef[];
    sortable?: boolean;
    filterable?: boolean;
    setCustomHeight?: string;
    hasBorder?: boolean;
    isColumnHeaderVerticallyCentered?: boolean;
    isRowAlignedTop?: boolean;
    columnHeaderHeight?: number;
    isAutoRowHeight?: boolean;
    initialHiddenColumns?: string[];
    updateHiddenColumns?: (hiddenColumns: string[]) => void;
    isLoading?: boolean;
    onCellEdit?: (
        updatedRow: GridValidRowModel,
        oldRow: GridValidRowModel
    ) => GridValidRowModel;
    minHeight?: string;
};

type StyledTableProps = { theme: Theme } & {
    hasBorder: boolean;
    isColumnHeaderVerticallyCentered: boolean;
    isRowAlignedTop: boolean;
    onRowClick?: GridEventListener<"rowClick">;
};

const TableContainer = styled(Box)(({ theme }) => ({
    height: theme.spacing(200),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
}));

const StyledGrid = styled(DataGrid, {
    shouldForwardProp: (prop) =>
        prop !== "hasBorder" &&
        prop !== "isColumnHeaderVerticallyCentered" &&
        prop !== "isRowAlignedTop",
})(
    ({
        theme,
        hasBorder,
        isColumnHeaderVerticallyCentered,
        isRowAlignedTop,
        onRowClick,
    }: StyledTableProps) => ({
        position: "static",

        paddingLeft: hasBorder ? 0 : "auto",
        paddingRight: hasBorder ? 0 : "auto",

        "& .MuiDataGrid-main": {
            border: hasBorder ? "1px solid #E0E0E0" : "none",
        },

        "& .MuiDataGrid-row": {
            cursor: onRowClick && "pointer",
            paddingTop: isRowAlignedTop && "5px",
            paddingBottom: isRowAlignedTop && "5px",

            "&:hover": {
                backgroundColor: onRowClick && "#f5f8ff",
            },

            "&:hover .show-on-hover": {
                visibility: "visible",
            },
        },
        "& .MuiButtonBase-root[title='Sort']": {
            paddingTop: 0,
            paddingBottom: 0,
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
            alignItems: isColumnHeaderVerticallyCentered
                ? "auto"
                : "flex-start",
            lineHeight: isColumnHeaderVerticallyCentered ? "auto" : "1.2em",
            paddingTop: isColumnHeaderVerticallyCentered
                ? "auto"
                : theme.spacing(2.5),
        },
        "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: isRowAlignedTop ? "flex-start" : "center",
        },
        "& .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .no-building-address": {
            color: theme.palette.primary.main,
        },
        "& .header-row": {
            fontWeight: 700,
            backgroundColor: theme.palette.grey[200],
            minHeight: "auto !important",
            maxHeight: "auto !important",
            lineHeight: "1em !important",
            padding: theme.spacing(2, 0),

            "&:after": {
                backgroundColor: "transparent",
            },
            "&:hover": {
                backgroundColor: theme.palette.grey[300],
            },
            "& > *": {
                minHeight: "auto !important",
                maxHeight: "auto !important",
                lineHeight: "1.2em !important",

                "&:not(:first-of-type)": {
                    fontStyle: "italic",
                    fontWeight: 500,
                },
            },
        },
    })
);

export const Table = (props: Props & DataGridProps) => {
    const {
        columns,
        sortable,
        filterable,
        setCustomHeight,
        hasBorder,
        isColumnHeaderVerticallyCentered = true,
        isRowAlignedTop,
        columnHeaderHeight,
        isAutoRowHeight,
        initialHiddenColumns,
        updateHiddenColumns,
        isLoading,
        onCellEdit,
        minHeight,
        ...rest
    } = props;

    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>(
        {}
    );

    const { t } = useTranslation();

    const mappedColumns = useMemo(
        () =>
            columns.map((column) => ({
                sortable,
                filterable,
                hide: false,
                hideSortIcons: false,
                disableSelectionOnClick: true,
                valueFormatter: getValueFormatter(t),
                ...column,
            })),
        [t, columns, sortable, filterable]
    );

    const rowHeight = isAutoRowHeight ? "auto" : 58;

    const initialState = useMemo(
        () => ({
            columns: {
                columnVisibilityModel: initialHiddenColumns?.reduce(
                    (columns, column) => ({
                        ...columns,
                        [column]: false,
                    }),
                    {}
                ),
            },
        }),
        [initialHiddenColumns]
    );

    /**
     * Code following MUI recipe to allow single click editing:
     * https://mui.com/x/react-data-grid/recipes-editing/#single-click-editing
     */
    const onColumnVisibilityModelChange = useCallback(
        (params: Record<string, boolean>) => {
            if (updateHiddenColumns) {
                updateHiddenColumns(
                    Object.keys(params).filter((column) => !params[column])
                );
            }
        },
        [updateHiddenColumns]
    );
    const handleCellClick = useCallback((params: GridCellParams) => {
        if (params.isEditable) {
            setCellModesModel((prevModel) => {
                return {
                    // Revert the mode of the other cells from other rows
                    ...Object.keys(prevModel).reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: Object.keys(prevModel[id]).reduce(
                                (acc2, field) => ({
                                    ...acc2,
                                    [field]: { mode: GridCellModes.View },
                                }),
                                {}
                            ),
                        }),
                        {}
                    ),
                    [params.id]: {
                        // Revert the mode of other cells in the same row
                        ...Object.keys(prevModel[params.id] || {}).reduce(
                            (acc, field) => ({
                                ...acc,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {}
                        ),
                        [params.field]: { mode: GridCellModes.Edit },
                    },
                };
            });
        }
    }, []);
    const handleCellModesModelChange = useCallback(
        (newModel: GridCellModesModel) => {
            setCellModesModel(newModel);
        },
        []
    );

    return (
        <TableContainer style={{ height: setCustomHeight, minHeight }}>
            <StyledGrid
                {...rest}
                localeText={{ toolbarExport: t("common.download", "Download") }}
                columns={mappedColumns}
                columnHeaderHeight={columnHeaderHeight ?? 68}
                getRowHeight={() => rowHeight}
                hideFooterSelectedRowCount={true}
                disableRowSelectionOnClick={true}
                disableColumnMenu={true}
                hasBorder={!!hasBorder}
                isColumnHeaderVerticallyCentered={
                    isColumnHeaderVerticallyCentered
                }
                isRowAlignedTop={!!isRowAlignedTop}
                initialState={initialState}
                onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                loading={isLoading ?? false}
                processRowUpdate={onCellEdit}
                cellModesModel={cellModesModel}
                onCellClick={handleCellClick}
                onCellModesModelChange={handleCellModesModelChange}
            />
        </TableContainer>
    );
};
