import { useQuery } from "@apollo/client";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
    AssetPropertyTree,
    GetAssetPropertyTreeQuery,
    GetAssetPropertyTreeQueryVariables,
} from "graphql-types/graphql";

import { flatPropertyTree } from "./asset-property-list.helper";
import { GET_ASSET_PROPERTY_TREE } from "./asset-property-list.query";
import { S } from "./asset-property-list.styles";

type Props = {
    assetId: string;
};

export const AssetPropertyList = (props: Props) => {
    const { assetId } = props;
    const { useConsumptionAllocation } = useFlags();

    const { data, loading } = useQuery<
        GetAssetPropertyTreeQuery,
        GetAssetPropertyTreeQueryVariables
    >(GET_ASSET_PROPERTY_TREE, {
        skip: !useConsumptionAllocation,
        variables: {
            assetId,
        },
    });

    if (loading) {
        return null;
    }

    const items = data?.getAssetPropertyTree || [];

    if (!items) {
        return null;
    }

    const flatPropertyList = flatPropertyTree(items as AssetPropertyTree[]);

    const list = flatPropertyList.map((item) => {
        const { id, depth, name, address } = item;

        const icon =
            depth > 0 ? <SubdirectoryArrowRightIcon color="info" /> : null;

        const paddingLeftValue = depth <= 1 ? 0 : `${depth * 20}px`;

        return (
            <S.Item key={id}>
                <S.Body sx={{ paddingLeft: paddingLeftValue }}>
                    {icon}
                    <S.Content>
                        <Typography variant="h4">{name}</Typography>
                        <Typography variant="body1">{`${address.HouseNumber} ${address.StreetName}, ${address.PostalCity}`}</Typography>
                    </S.Content>
                </S.Body>
            </S.Item>
        );
    });

    return <S.List>{list}</S.List>;
};
