import { gql } from "@apollo/client";

export const ASSET_AUTOMATIC_SOURCES = gql`
    query GetAssetAutomaticSources($locationId: ID!) {
        location(id: $locationId) {
            id
            displayName
            automaticDataSources {
                id
                from
                to
                type
                consumptionType
                identifier
                dataQuality
                address
                nationalPropertyId
                vertical
                status
                source
                allocationNote
                integrationId
                allocationObjectType
                propertyIds
                landlordOwnership
                tenantOwnership
                otherOwnership
            }
        }
    }
`;
