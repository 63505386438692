import { gql } from "@apollo/client";

export const UPDATE_ASSET_INTEGRATION = gql`
    mutation UpdateAssetIntegration(
        $id: ID!
        $update: UpdateAssetIntegrationInput!
    ) {
        updateOneLocationIntegration(input: { id: $id, update: $update }) {
            id
        }
    }
`;
