import { gql } from "@apollo/client";

export const LEGACY_BENCHMARKS_QUERY = gql`
    query GetLegacyBenchmarks(
        $assetGroupId: ID
        $valueType: LegacyBenchmarkValueType
    ) {
        getLegacyBenchmarks(assetGroupId: $assetGroupId) {
            id
            internalIdentifier
            benchmarkType
            segment
            countryCode
            numberOfBuildings
            updatedAt
            values(filter: { type: { eq: $valueType } }) {
                id
                from
                to
                category
                value
                unit
                type
            }
        }
    }
`;
