import { Box } from "@mui/material";
import { Children, PropsWithChildren, isValidElement, useState } from "react";

import { S } from "./tabs.styles";
import { StyledTabProps, StyledTabsProps, TabPanelProps } from "./tabs.types";

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;
    return (
        <Box role="tabpanel" hidden={value !== index}>
            {children}
        </Box>
    );
};

export const Tab = S.Tab;

export const Tabs = (props: StyledTabsProps) => {
    const { children, value: defaultValue = 0, sx } = props;

    const [value, setValue] = useState(defaultValue);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const childrenArray = Children.toArray(children);
    const tabs: PropsWithChildren<StyledTabProps>[] = childrenArray.map(
        (child) => {
            if (!isValidElement(child)) return undefined;
            return child.props;
        }
    );

    return (
        <>
            <S.Tabs value={value} onChange={handleChange} sx={sx}>
                {tabs.map((tab, index) => (
                    <S.Tab
                        key={`${tab.label}-${index}-tab`}
                        label={tab.label}
                    />
                ))}
            </S.Tabs>
            <Box>
                {tabs.map((tab, index) => (
                    <TabPanel
                        key={`${tab.label}-${index}-panel`}
                        index={index}
                        value={value}
                    >
                        {tab.children}
                    </TabPanel>
                ))}
            </Box>
        </>
    );
};
