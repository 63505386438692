import { Checkbox } from "@mui/material";

import { NumberInput } from "glue";

import { S } from "./drawerFields.style";

type Props = {
    items: { label: string; value: number; isChecked: boolean }[];
    disabled?: boolean;
    onCheckItem: (index: number) => void;
    onEditItem: (index: number, value: number) => void;
};

export const EditableFieldList = (props: Props) => {
    const { items, disabled, onEditItem, onCheckItem } = props;

    return (
        <S.BorderBox>
            {items.map((item, index) => (
                <S.FieldBox key={item.label}>
                    <S.InputLabel htmlFor={item.label} bold={item.isChecked}>
                        {!disabled && (
                            <Checkbox
                                size="small"
                                checked={item.isChecked}
                                onChange={() => onCheckItem(index)}
                            />
                        )}
                        {item.label}
                    </S.InputLabel>

                    {item.isChecked && (
                        <NumberInput
                            size="small"
                            endAdornment="%"
                            disabled={disabled}
                            value={item.value}
                            inputProps={{ min: 0, max: 100 }}
                            onChange={(e) =>
                                onEditItem(index, Number(e.target.value))
                            }
                        />
                    )}
                </S.FieldBox>
            ))}
        </S.BorderBox>
    );
};
