import { createTheme } from "@mui/material/styles";
import "@fontsource/lato";
import { Shadows } from "@mui/material/styles/shadows";

import { convertToRem } from "../utils/maths";

declare module "@mui/material/styles/createPalette" {
    interface EPCColor {
        main: string;
        light: string;
    }
    interface EPCPalette {
        a: EPCColor;
        b: EPCColor;
        c: EPCColor;
        d: EPCColor;
        e: EPCColor;
        f: EPCColor;
        g: EPCColor;
    }

    interface Palette {
        purple: Palette["primary"];
        cream: Palette["primary"];
        palePurple: Palette["primary"];
        heat: Palette["primary"];
        electricity: Palette["primary"];
        cooling: Palette["primary"];
        water: Palette["primary"];
        transport: Palette["primary"];
        waste: Palette["primary"];
        materials: Palette["primary"];
        energy: Palette["primary"];
        uncategorized: Palette["primary"];

        low: Palette["primary"];
        medium: Palette["primary"];
        high: Palette["primary"];

        epc: EPCPalette;

        // SourceStatuses
        active: Palette["primary"];
        expired: Palette["primary"];
        manual: Palette["primary"];
    }

    interface PaletteOptions {
        purple: Palette["primary"];
        cream: Palette["primary"];
        palePurple: Palette["primary"];
        heat: Palette["primary"];
        electricity: Palette["primary"];
        cooling: Palette["primary"];
        water: Palette["primary"];
        transport: Palette["primary"];
        waste: Palette["primary"];
        materials: Palette["primary"];
        energy: Palette["primary"];
        uncategorized: Palette["primary"];

        low: Palette["primary"];
        medium: Palette["primary"];
        high: Palette["primary"];

        // SourceStatuses
        active: Palette["primary"];
        expired: Palette["primary"];
        manual: Palette["primary"];

        epc: EPCPalette;
    }
}

declare module "@mui/material/Chip" {
    interface ChipPropsColorOverrides {
        active: true;
        expired: true;
        manual: true;
    }
}

const verticals = {
    heat: {
        main: "#F94F4F",
        light: "#FDA894",
    },
    electricity: {
        main: "#FFB224",
        light: "#FEDA7A",
    },
    water: {
        main: "#2FA9DD",
        light: "#78C8EB",
    },
    transport: {
        main: "#9063C9",
        light: "#BBA4D8",
    },
    waste: {
        main: "#47A76D",
        light: "#9BD2B1",
    },
    cooling: {
        main: "#007bff",
        light: "#00bcd4",
    },
    materials: {
        main: "#90A4AE",
        light: "#C3C6C4",
    },
    energy: {
        main: "#c8d446",
        light: "#a5ab60",
    },
    uncategorized: {
        main: "#90A4AE",
        light: "#C3C6C4",
    },
};

const epcPaletteOptions = {
    a: {
        main: "#077d39",
        light: "#daebe1",
    },
    b: {
        main: "#74a634",
        light: "#eaf2e2",
    },
    c: {
        main: "#d9bf3a",
        light: "#f9f5e2",
    },
    d: {
        main: "#d27e17",
        light: "#f8ecdd",
    },
    e: {
        main: "#cb4c23",
        light: "#f7e4de",
    },
    f: {
        main: "#810814",
        light: "#ecdadc",
    },
    g: {
        main: "#5a2a57",
        light: "#e6dfe6",
    },
};

const lineHeight = 1.43;

let theme = createTheme({
    typography: {
        fontSize: 16,
        h1: {
            fontSize: convertToRem(30),
            lineHeight,
            fontWeight: 700,
        },
        h2: {
            fontSize: convertToRem(26),
            lineHeight,
            fontWeight: 700,
        },
        h3: {
            fontSize: convertToRem(20),
            fontWeight: 700,
            lineHeight,
        },
        h4: {
            fontSize: convertToRem(18),
            lineHeight,
            fontWeight: 700,
        },
        h5: {
            fontSize: convertToRem(14),
            lineHeight,
        },
        h6: {
            fontSize: convertToRem(12),
            lineHeight,
        },
        body1: {
            fontSize: "1rem",
            lineHeight,
        },
        body2: {
            fontSize: "0.85rem",
            lineHeight,
        },
        button: {
            fontSize: "1rem",
            lineHeight,
            textTransform: undefined,
        },
        fontFamily: ["Lato", "Tahoma", "sans-serif"].join(","),
    },
    shadows: Array(25).fill("none") as Shadows, //disable ALL shadows in application
    spacing: 4,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1130,
            lg: 1280,
            xl: 1920,
        },
    },
});

theme = createTheme(theme, {
    palette: {
        background: {
            default: "#F7F7F8",
        },
        primary: {
            main: "#1E3B4D",
            light: "#e6edef",
            dark: "#191A1C",
            contrastText: "#fcfcfc",
        },
        secondary: {
            main: "#CDDCE0",
        },
        palePurple: {
            main: "#E6E9F0",
        },
        purple: {
            main: "#797EF5",
        },
        cream: {
            main: "#FDFEF5",
        },
        text: {
            primary: "#191A1C",
        },
        common: {
            black: "#191A1C",
        },
        error: {
            main: "#F94F4F",
        },
        warning: {
            light: "#FFCE5C",
            main: "#ffb810",
            dark: "#F5AB00",
        },
        success: {
            main: "#43A047",
        },
        info: {
            main: "#2FA9DD",
            light: "#78C8EB",
        },
        // SourceStatuses
        active: theme.palette.augmentColor({
            color: { main: "#57C175", contrastText: "#FFF" },
        }),
        expired: theme.palette.augmentColor({
            color: { main: "#FF0000", contrastText: "#FFF" },
        }),
        manual: theme.palette.augmentColor({
            color: { main: "#57C175", contrastText: "#FFF" },
        }),
        header: theme.palette.augmentColor({
            color: { main: theme.palette.grey[100] },
        }),
        ...verticals,
        epc: epcPaletteOptions,
    },
});

theme = createTheme(theme, {
    mixins: {
        MuiDataGrid: {
            // Pinned columns sections
            pinnedBackground: theme.palette.common.white,
            // Headers, and top & bottom fixed rows
            containerBackground: theme.palette.common.white,
        },
    },

    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 0,
                    fontSize: theme.typography.body1.fontSize,
                    paddingLeft: theme.spacing(7),
                    paddingRight: theme.spacing(7),

                    "& .MuiDataGrid-columnHeaderTitle": {
                        color: theme.palette.primary.main,
                        fontSize: convertToRem(14),
                        alignItems: "baseline",
                        fontWeight: 900,
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                    },
                    "& .error": {
                        backgroundColor: theme.palette.error.light,
                        "&:hover": {
                            backgroundColor: `${theme.palette.error.main} !important`,
                        },
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        border: 0,
                        "&:focus": {
                            border: 0,
                            outline: 0,
                        },
                    },
                    "& .MuiDataGrid-footer": {
                        justifyContent: "flex-end",
                        borderTop: `1px solid ${theme.palette.grey[300]}`,
                    },
                    "& .MuiTablePagination-toolbar": {
                        color: theme.palette.grey[400],

                        "& > p": {
                            marginBottom: 0,
                        },
                    },
                    "& .MuiDataGrid-row": {
                        borderBottom: "1px solid rgb(236, 236, 236)",
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                },
                select: {
                    "&:focus": {
                        backgroundColor: "white",
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(5, 6),
                },
                action: {
                    marginRight: theme.spacing(0),
                },
                outlinedError: {
                    color: theme.palette.common.black,
                    backgroundColor: theme.palette.error.light,
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                },
            },
        },
        MuiGridMenu: {
            styleOverrides: {
                root: {
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: theme.spacing(1),
                    boxShadow: "0 8px 24px 0 rgba(133, 133, 133, 0.1)",
                },
            },
        },
        MuiGridPanel: {
            styleOverrides: {
                root: {
                    "& .MuiFormLabel-root": {
                        color: theme.palette.primary.main,
                    },
                },
                paper: {
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: theme.spacing(1),
                    boxShadow: "0 8px 24px 0 rgba(133, 133, 133, 0.1)",
                },
            },
        },

        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: theme.spacing(2),
                    fontSize: theme.typography.h5.fontSize,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.main,

                    "& svg": {
                        width: "0.7em",
                        height: "0.7em",
                    },
                },
            },
        },
    },
});

export { theme };
