import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { GridEventListener, useGridApiRef } from "@mui/x-data-grid";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Table, TablePagination } from "components/Table";
import {
    GetAssetAutomaticSourcesQuery,
    GetAssetAutomaticSourcesQueryVariables,
} from "graphql-types/graphql";

import {
    formatAutomaticDataRows,
    getAllocationColumns,
    getColumns,
} from "./automaticSourcesTable.helpers";
import { ASSET_AUTOMATIC_SOURCES } from "./automaticSourcesTable.query";
import { AutomaticSourceRowData } from "./automaticSourcesTable.types";
import AutomaticSourceToolbar from "./components/AutomaticSourceToolbar";
import { DataSourceDrawer } from "./components/DataSourceDrawer";
import { AutomaticSource } from "../AssetSources.helpers";
import { ASSET_PAGE_HEADER_HEIGHT } from "../ManualSourcesTable";

type Props = {
    locationId: string;
};

export const AutomaticSourcesTable = (props: Props) => {
    const { locationId } = props;
    const { t } = useTranslation(["report"]);

    const { useConsumptionAllocation } = useFlags();

    const { data, loading } = useQuery<
        GetAssetAutomaticSourcesQuery,
        GetAssetAutomaticSourcesQueryVariables
    >(ASSET_AUTOMATIC_SOURCES, {
        variables: { locationId },
    });

    const [sources, setSources] = useState<AutomaticSource[]>([]);
    const [rows, setRows] = useState<AutomaticSource[]>([]);
    const [selectedSource, setSelectedSource] = useState<
        AutomaticSourceRowData | undefined
    >(undefined);

    const [sourceDrawerOpen, setSourceDrawerOpen] = useState(false);

    const columns = useMemo(() => {
        const defaultColumns = getColumns(t);

        if (!useConsumptionAllocation) return defaultColumns;

        return [...defaultColumns, ...getAllocationColumns(t)];
    }, [t, useConsumptionAllocation]);

    const apiRef = useGridApiRef();

    useEffect(() => {
        if (!data) return;

        const { automaticDataSources } = data.location;
        setSources(automaticDataSources);
        setRows(automaticDataSources);
    }, [data]);

    const onRowClick: GridEventListener<"rowClick"> = (params) => {
        setSourceDrawerOpen(true);
        setSelectedSource(params.row);
    };

    return (
        <Box>
            <AutomaticSourceToolbar
                apiRef={apiRef}
                sources={sources}
                setSources={setRows}
                locationDisplayName={data?.location.displayName}
            />
            <DataSourceDrawer
                data={selectedSource}
                isOpen={sourceDrawerOpen}
                onClose={() => setSourceDrawerOpen(false)}
            />
            <Table
                sx={{
                    padding: 0,
                    "& .MuiDataGrid-columnHeader[data-field='source']": {
                        paddingLeft: 6,
                    },
                    "& .MuiDataGrid-cell[data-field='source']": {
                        paddingLeft: 0,
                    },
                    "& .MuiDataGrid-columnHeader": {
                        cursor: "pointer",
                    },
                    "& .MuiDataGrid-root": {
                        padding: 0,
                    },
                }}
                columns={columns}
                rows={formatAutomaticDataRows(rows)}
                onRowClick={useConsumptionAllocation ? onRowClick : undefined}
                setCustomHeight={`calc(100vh - ${ASSET_PAGE_HEADER_HEIGHT}px)`}
                minHeight="400px"
                apiRef={apiRef}
                slotProps={{
                    pagination: {
                        ActionsComponent: TablePagination,
                    },
                }}
                isLoading={loading}
            />
        </Box>
    );
};
