import { gql } from "@apollo/client";

export const GET_ASSET_PROPERTY_TREE_FRAGMENT = gql`
    fragment AssetPropertyTreeFragment on AssetPropertyTree {
        id
        name
        address
    }
`;

export const GET_ASSET_PROPERTY_TREE = gql`
    query GetAssetPropertyTree($assetId: ID!) {
        getAssetPropertyTree(assetId: $assetId) {
            id
            name
            address
            children {
                ...AssetPropertyTreeFragment
                children {
                    ...AssetPropertyTreeFragment
                    children {
                        ...AssetPropertyTreeFragment
                        children {
                            ...AssetPropertyTreeFragment
                            children {
                                ...AssetPropertyTreeFragment
                                children {
                                    ...AssetPropertyTreeFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${GET_ASSET_PROPERTY_TREE_FRAGMENT}
`;
