import { AssetPropertyTree } from "graphql-types/graphql";

import { PropertyListFlatTree } from "./asset-preoperty-list.types";

export const flatPropertyTree = (
    properties: AssetPropertyTree[],
    depth = 0
): PropertyListFlatTree[] => {
    let result: PropertyListFlatTree[] = [];

    properties.forEach((property) => {
        // Add the current property with its depth
        result.push({ ...property, depth });

        // If there are children, recursively add them with incremented depth
        if (property.children && property.children.length > 0) {
            result = result.concat(
                flatPropertyTree(property.children, depth + 1)
            );
        }
    });

    return result;
};
