import { Box, useTheme } from "@mui/material";

import { getStatusColor } from "containers/AssetPage/AssetDataOverview/AutomaticSourcesTable/automaticSourcesTable.helpers";

import { DataSourceStatus } from "./DataStatusCell";

type AutomaticDataSourceStatusCellProps = {
    status: DataSourceStatus;
    source?: string;
};

export const AutomaticDataSourceStatusCell = (
    props: AutomaticDataSourceStatusCellProps
) => {
    const { status, source } = props;
    const { palette } = useTheme();

    const statusColor = getStatusColor(status);
    return (
        <Box
            sx={{
                borderLeft: 5,
                borderColor: palette[statusColor].main,
                paddingLeft: 5,
            }}
        >
            {source ?? "-"}
        </Box>
    );
};
