import { capitalize, startCase, toLower } from "lodash";
import { useTranslation } from "react-i18next";

import { useFilterTranslations } from "components/AssetsGroup";
import {
    getLegacyBenchmarkId,
    getLegacyBenchmarkMarkerColor,
} from "components/PortfolioDashboard/Charts/utils";
import { LegacyBenchmarkMarker } from "components/types";
import { LegacyBenchmark } from "graphql-types/graphql";

import { useRegionTranslation } from "./useRegionTranslation";

function formatSegment(input: string): string {
    return input ? startCase(toLower(input.replace(/_/g, " "))) : "";
}

export function useLegacyBenchmarkMarker() {
    const { t } = useTranslation();
    const { getRegionName } = useRegionTranslation();
    const { translations } = useFilterTranslations();

    const convertToLegacyBenchmarkMarker = (
        legacyBenchmarks: LegacyBenchmark[]
    ): LegacyBenchmarkMarker[] => {
        let benchmarks: LegacyBenchmarkMarker[] = [];

        legacyBenchmarks.forEach((legacyBenchmark) => {
            const {
                benchmarkType,
                countryCode,
                segment,
                values,
                numberOfBuildings,
            } = legacyBenchmark;

            const id = getLegacyBenchmarkId(legacyBenchmark);
            const currentBenchmark = benchmarks.find(
                (benchmark) => benchmark?.id === id
            );

            if (!currentBenchmark) {
                const color = getLegacyBenchmarkMarkerColor(benchmarkType);
                const title = `${translations[benchmarkType]} ${capitalize(
                    t("benchmark.singular", "benchmark")
                )}`;

                const subTitle = `${getRegionName(
                    countryCode ?? ""
                )}, ${formatSegment(segment)}`;

                const description = `${t(
                    "benchmark.legacyBenchmarkDescription",
                    "Legacy’s Benchmarks are metrics designed to help property owners assess building emissions and energy performance using specific thresholds"
                )}`;

                benchmarks.push({
                    ...legacyBenchmark,
                    id,
                    color,
                    title,
                    isVisible: false,
                    subTitle,
                    description,
                    values,
                    numberOfBuildings,
                });
                return;
            }

            benchmarks = benchmarks.map((benchmark) => {
                if (benchmark.id !== currentBenchmark.id) {
                    return benchmark;
                }

                return {
                    ...benchmark,
                    values: [...benchmark.values, ...values],
                    numberOfBuildings:
                        benchmark.numberOfBuildings + numberOfBuildings,
                };
            });
        });

        return benchmarks;
    };

    return {
        convertToLegacyBenchmarkMarker,
    };
}
