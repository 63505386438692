type OwnershipItem = {
    label: string;
    value: number;
    isChecked: boolean;
    savedValue: number;
};

export const ownershipsEquals100 = (ownership: OwnershipItem[]) => {
    const totalOwnership = ownership
        .filter((item) => item.isChecked)
        .reduce((sum, item) => sum + item.value, 0);

    return totalOwnership === 100;
};
