import { Box, Typography, useTheme, Chip, Stack } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import {
    getDataQualityColor,
    mapDataQualitySources,
} from "components/DataQuality/DataQuality.helpers";
import Popover from "components/Popovers/Popover";
import {
    AssetCoverageAssessmentFragment,
    DataQualityTypes,
} from "graphql-types/graphql";
import { MONTHS_IN_YEAR } from "utils/date.utils";
import { hexToRgba } from "utils/strings.helpers";

import {
    isNextMonthInAssessments,
    isSourceInParentMonth,
} from "./AssetDataOverview.helper";

type BestEffortCoverageAssessment = AssetCoverageAssessmentFragment & {
    dataQuality: DataQualityTypes;
    sources: { source: string; quality: DataQualityTypes }[];
};

export type DataCoverageAssessments = BestEffortCoverageAssessment & {
    source: string;
    dateRanges: string[];
    consumptions: string;
    emissions: string;
};

const SplitText = ({ title, content }: { title: string; content: string }) => (
    <Typography variant="body2" display="flex" justifyContent="space-between">
        {title}:
        <Box component="span" fontWeight={700}>
            {content}
        </Box>
    </Typography>
);

const TimelinePopoverContent = ({
    month,
    isBestEffort,
    assessments,
}: {
    month: number;
    isBestEffort: boolean;
    assessments?: Record<string, DataCoverageAssessments>;
}) => {
    const { palette } = useTheme();
    const { t } = useTranslation();

    if (!assessments || !assessments[month]) {
        return (
            <Typography variant="body2">
                {t("dataLocationCategory.noData", "No Data")}
            </Typography>
        );
    }

    if (isBestEffort) {
        return (
            <Stack gap={1}>
                {assessments[month].sources?.map(({ source, quality }) => (
                    <Chip
                        key={source}
                        size="small"
                        color="primary"
                        label={mapDataQualitySources(source, t)}
                        sx={{
                            borderRadius: 1,
                            background: hexToRgba(
                                getDataQualityColor(quality, palette),
                                0.85
                            ),
                        }}
                    />
                ))}
            </Stack>
        );
    }

    const assessment = assessments[month];

    return (
        <Box width="max-content">
            {assessment.dateRanges.map((dateRange, index) => (
                <Typography key={index} variant="body2" color="grey.600">
                    {dateRange}
                </Typography>
            ))}
            <SplitText
                title={t("assetCoverage.consumption", "Consumption")}
                content={assessment.consumptions}
            />
            <SplitText
                title={t("assetCoverage.emission", "Emission")}
                content={assessment.emissions}
            />
        </Box>
    );
};

const AssetBestEffortTimeline = ({
    color,
    assessments,
    parentAssessments,
}: {
    color?: string;
    assessments: Record<string, DataCoverageAssessments>;
    parentAssessments?: Record<string, DataCoverageAssessments>;
}) => {
    const { palette } = useTheme();

    const getBackgroundColor = (month: number, color?: string) => {
        if (!color) {
            return getDataQualityColor(assessments[month].dataQuality, palette);
        }

        return !parentAssessments ||
            isSourceInParentMonth(month, assessments, parentAssessments)
            ? color
            : hexToRgba(color, 0.5);
    };

    return (
        <Box sx={{ gap: 2, height: 1, width: "100%", display: "flex" }}>
            {_.range(1, MONTHS_IN_YEAR + 1).map((month) => {
                const Parent = () =>
                    assessments[month] ? (
                        <Box
                            sx={{
                                height: 0.25,
                                margin: "auto",
                                position: "absolute",
                                backgroundColor: getBackgroundColor(
                                    month,
                                    color
                                ),
                                width: isNextMonthInAssessments(
                                    month,
                                    assessments
                                )
                                    ? "calc(100% + 8px)"
                                    : "100%",
                            }}
                        />
                    ) : null;

                // eslint-disable-next-line no-extra-boolean-cast
                const isBestEffort = !Boolean(parentAssessments);

                const monthLabel = new Date(2021, month - 1).toLocaleString(
                    "default",
                    {
                        month: "long",
                    }
                );
                return (
                    <Popover
                        key={month}
                        parent={Parent}
                        mouseOver={true}
                        parentBoxProps={{
                            sx: {
                                height: 1,
                                display: "flex",
                                position: "relative",
                                alignItems: "center",
                                width: `${100 / MONTHS_IN_YEAR}%`,
                                backgroundColor: color
                                    ? hexToRgba(color, 0.1)
                                    : "grey.200",
                            },
                        }}
                    >
                        <Box
                            display={isBestEffort ? "flex" : "display"}
                            gap={8}
                            alignItems="center"
                        >
                            <Typography variant="body2" fontWeight={700} mb={1}>
                                {monthLabel}
                            </Typography>
                            <TimelinePopoverContent
                                month={month}
                                assessments={assessments}
                                isBestEffort={isBestEffort}
                            />
                        </Box>
                    </Popover>
                );
            })}
        </Box>
    );
};

export default AssetBestEffortTimeline;
