import { TFunction } from "i18next";
import _ from "lodash";
import { match } from "ts-pattern";

import { UnitMass } from "components/AssessmentStats/types";
import {
    BenchmarkMarker,
    BenchmarkMarkers,
    LegacyBenchmarkMarker,
} from "components/types";
import { AbsoluteLineSeriesType } from "glue/Chart/chart.types";
import {
    Benchmark,
    BenchmarkType,
    LegacyBenchmark,
    LegacyBenchmarkType,
    LegacyBenchmarkValueType,
} from "graphql-types/graphql";
import { isYearInRange } from "utils/date.utils";

import { getFormattedValueToMassUnit } from "../charts.helper";

export function getLegacyBenchmarkId(legacyBenchmark: LegacyBenchmark) {
    const { benchmarkType, countryCode, segment } = legacyBenchmark;
    return `legacy-benchmark-${benchmarkType}-${countryCode}-${segment}`;
}

export function getBenchmarkMarkerColor(benchmark: Benchmark): string {
    return match(benchmark.type)
        .with(BenchmarkType.MARKET, () => {
            const { countryCode } = benchmark;
            return match(countryCode)
                .with("DK", () => "#FF7300")
                .with("GB", () => "#CD0000")
                .otherwise(() => "#000000");
        })
        .with(BenchmarkType.BASELINE, () => "#00A8CD")
        .exhaustive();
}

export function getLegacyBenchmarkMarkerColor(
    legacyBenchmarkType: LegacyBenchmarkType
): string {
    return match(legacyBenchmarkType)
        .with(LegacyBenchmarkType.BBR_PROPERTY_TYPE, () => "#4E3CC3")
        .with(LegacyBenchmarkType.CRREM_PROPERTY_TYPE, () => "#32CD32")
        .with(LegacyBenchmarkType.BUILDING_AGE, () => "#FFD700")
        .with(LegacyBenchmarkType.BUILDING_SIZE, () => "#8A2BE2")
        .with(LegacyBenchmarkType.CONSUMPTION_TYPE, () => "#FF69B4")
        .with(LegacyBenchmarkType.LEGACY_PROPERTY_TYPE, () => "#20B2AA")
        .with(LegacyBenchmarkType.NATIONAL, () => "#DA70D6")
        .with(LegacyBenchmarkType.REGION, () => "#D2B48C")
        .with(LegacyBenchmarkType.VERTICAL, () => "#FF4500")
        .exhaustive();
}

const getBenchmarkLabel = (type: BenchmarkType, t: TFunction) =>
    match(type)
        .with(BenchmarkType.MARKET, () =>
            t("benchmark.countryBenchmark", "Country Benchmark")
        )
        .with(BenchmarkType.BASELINE, () =>
            t("benchmark.portfolioBenchmark", "Portfolio Benchmark")
        )
        .exhaustive();

const getLegacyBenchmarkLabel = (legacyBenchmark: LegacyBenchmarkMarker) => {
    const { benchmarkType, title, subTitle, countryCode } = legacyBenchmark;

    const label = title.replace(/\s\w+$/, "");
    const segment = subTitle.split(", ")[1];

    const segmentLabel = match(benchmarkType)
        .with(LegacyBenchmarkType.NATIONAL, () => ` (${countryCode})`)
        .with(LegacyBenchmarkType.REGION, () => ` (${segment.split(" ")[1]})`)
        .otherwise(() => ` (${segment})`);

    return label + segmentLabel;
};

export function benchmarksToSeries(
    benchmarkMarkers: BenchmarkMarkers,
    numberOfLabels: number,
    isAssetChart: boolean,
    t: TFunction
): AbsoluteLineSeriesType[] {
    const markers = benchmarkMarkers.filter(
        (marker) =>
            marker.benchmarkType === BenchmarkType.BASELINE ||
            marker.benchmarkType === BenchmarkType.MARKET
    ) as BenchmarkMarker[];

    return markers.map((benchmarkMarker) => {
        const benchmarkSeriesData = _.range(numberOfLabels).map(() =>
            benchmarkMarker.isVisible ? benchmarkMarker.value : null
        );

        const label = getBenchmarkLabel(benchmarkMarker.benchmarkType, t);

        return {
            type: "line",
            showMark: false,
            id: `benchmark-${benchmarkMarker.id}`,
            data: benchmarkSeriesData,
            color: benchmarkMarker.color,
            valueFormatter: (value: any) =>
                getFormattedValueToMassUnit(
                    value,
                    UnitMass.KILOGRAM,
                    t,
                    !isAssetChart
                ) ?? "",
            label,
        };
    });
}

export function legacyBenchmarkToSeries(
    years: number[],
    benchmarkMarkers: BenchmarkMarkers,
    isAssetChart: boolean,
    t: TFunction
): AbsoluteLineSeriesType[] {
    const markers = benchmarkMarkers.filter(
        (marker) => marker.isVisible && marker.id.includes("legacy-benchmark")
    ) as LegacyBenchmarkMarker[];

    const markerEmissions = markers.map((marker) => {
        const emissionValues = marker.values.filter(
            (value) => value.type === LegacyBenchmarkValueType.EMISSION
        );

        const values = years.map((year) => {
            const value = emissionValues.find((value) =>
                isYearInRange(year, value.from, value.to)
            );

            return value ? value.value : null;
        });

        return {
            ...marker,
            label: getLegacyBenchmarkLabel(marker),
            values,
        };
    });

    return markerEmissions.map((legacyBenchmarkMarker) => {
        return {
            type: "line",
            showMark: false,
            id: legacyBenchmarkMarker.id,
            data: legacyBenchmarkMarker.values.map((value) => value),
            color: legacyBenchmarkMarker.color,
            label: legacyBenchmarkMarker.label,
            valueFormatter: (value: any) =>
                getFormattedValueToMassUnit(
                    value,
                    UnitMass.KILOGRAM,
                    t,
                    !isAssetChart
                ) ?? "",
        };
    });
}
